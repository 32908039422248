// i18next-extract-mark-ns-start products-pisdyn-fem

import { PISDYN_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductPisdynPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content">
						<p>
							PISDYN incorporates an advanced interface that enables
							the user to use FE models to calculate mass and
							stiffness metrics for dynamic and compliant analysis. It
							can also be used to calculate deformations due to
							thermal, pressure and inertia loads. Any combination of
							model level can be chosen for the piston or liner-side
							assemblies.
						</p>
						<p>
							With its internal FE solver, PISDYN has the capability
							to perform all required analyses. Alternatively, if the
							user prefers, appropriate analysis decks for major
							third-party solvers (e.g. Abaqus and Nastran) can also
							be set up.
						</p>
						<p>
							The post-solution, PISDYN FE interface can also apply
							calculated forces to component FE models providing
							boundary conditions for FE stress analyses.
						</p>
						<h2>Key features</h2>
						<ul>
							<li>
								Interactive 3D graphical user interface (RAPID GUI)
							</li>
							<li>Automatic selection of sets for loading</li>
							<li>
								Vectorized sparse solver (VSS) static (Guyan) and
								dynamic (component mode synthesis (CMS)) reduction
							</li>
							<li>Thermal, inertial and gas pressure deformations</li>
							<li>Temperature-dependent material properties</li>
							<li>
								Complete piston and liner (or engine block) models
								with optional half model
							</li>
							<li>Inertia relief for static models</li>
							<li>
								Built-in translators from commercially available FE
								packages
							</li>
							<li>Pressure loading of cylinder head</li>
							<li>
								Back-substitution of results for complete
								piston/liner FE analyses
							</li>
							<li>Piston and liner dynamic models</li>
							<li>Mass and damping</li>
							<li>User-defined modal damping characteristic</li>
							<li>Critical damping ratio against frequency</li>
							<li>Critical damping applied to each mode</li>
							<li>User selects the number of dynamic modes</li>
							<li>
								Improved vibration analysis for piston-slap
								prediction
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{PISDYN_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductPisdynPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-pisdyn-fem", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/pisdyn-hero.jpg" }) {
		...imageBreaker
	}
}
`;

